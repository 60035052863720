<template>
  <swiper
    class="swiper-navigations"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >
    <swiper-slide v-for="(data, index) in slider" :key="index">

      <div v-if="data.image" class="bg-img bg-df top-radius-5" :style="`background-image: url(`+ data.image + `)`" @click="announcements_click(data.id)">
          <b-img :src='require("@/assets/images/banner/Dashboard-slider-1.jpg")' fluid class="fade" />
      </div>
      <!-- else -->
      <div v-else class="bg-img bg-df top-radius-5" :style="`background-image: url(`+ require('@/assets/images/itees/admin/ITEES-logo-slider.png') + `); background-size: auto;     background-color: white`" @click="announcements_click(data.id)">
          <b-img :src='require("@/assets/images/banner/Dashboard-slider-1.jpg")' fluid class="fade" />
      </div>
      <div class="info">
          <div class="title size-16">{{ data.title }}</div>
          <div class="subtitle size-14">{{ data.description }}</div>
      </div>
    </swiper-slide>
    <div slot="pagination" class="swiper-pagination" />

  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const swiperOptions = {
      spaceBetween: 30,
      lazy: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // loop: true,
      autoplay: {
       delay: 12000,
     },
    };
    return {
      onSwiper,
      onSlideChange,
      swiperOptions,
    };
  },
  data() {
      return {          
        slider: []
      }
  },
  created(){
    this.fetchUserful();      
  },
  methods: {
    announcements_click(id){
      this.$router.push(`/admin/announcements/${id}`);
    },
    fetchUserful(){
        this.$store
        .dispatch('auth/fetchUserful', {
          limit: 3,
          filter:{
            "list_search": [
              { "field": "type","keyword": "1"}, //type: 1 -> announcements
              { "field": "status","keyword": "1"}
            ] 
          },
          sort: {
            "field": "id","type": "desc"
          }                 
        })
        .then(response => {
          this.slider = response.data.data.data
          // console.log('slider - ', response.data.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  }
};
</script>
<style lang="scss">
  .bg-img{
    height: 150px;
  }
  .swiper-navigations {
    .info{
      background-color: #fff;
      padding: 30px 20px 20px;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      /* identical to box height */
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 10px;
    }
    .subtitle {
      color: rgba(0, 0, 0, 0.8);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .swiper-pagination-bullets {
      bottom: 84px !important;
      .swiper-pagination-bullet {
        width: 20px;
        height: 5px;
        border-radius: 5px;
        background: #c4c4c4 !important;
        opacity: 0.5 !important;
      }
      .swiper-pagination-bullet-active {
        background: #c4c4c4 !important;
        opacity: 1 !important;
      }
    }
  }
</style>
