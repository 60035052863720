<template>
	<div>
		<b-row class="mb-20">
			<b-col>
				<b-card-code title="My Active Projects" no-body
					class="active-project pb-10 card-icon-none black-80-child capitalize-head w-40-percent-cl-1"
					:sub-title="items.length">
					<b-table :sticky-header="stickyHeader" responsive :items="items" :fields="fields"
						class="mb-0 cursor-pointer" show-empty @row-clicked="activity_Project">
						<template #head(country_name)="data">
							<span class="d-block text-left">{{ data.label }}</span>
						</template>
						<template #cell()="data">
							<span class="">{{ data.value }}</span>
						</template>
						<template #empty="scope">
							<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No
								data</h4>
						</template>
					</b-table>
				</b-card-code>
			</b-col>
		</b-row>
		<template v-if="permission.project.read">
			<b-card-code title="Projects Listing" no-body id="list-project"
				class="list-project pb-10 card-icon-none black-80-child mb-20 capitalize-head w-20-percent-cl-1">
				<b-table :sticky-header="true" responsive :items="projects_listing_items" :fields="projects_listing_field"
					class="mb-0 cursor-pointer" @head-clicked="sortClicked" @row-clicked="project_listing_detail"
					show-empty>
					<template #head()="data">
						<span class="">{{ data.label }}</span>
						<span
							:class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
							style="position: relative; left: 25%;">

							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
									stroke-linecap="round" stroke-linejoin="round" />
								<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</span>
					</template>
					<template #cell(country)="data">
						<span class="d-block text-left">{{ data.item.name }}</span>
					</template>
					<template #cell(active)="data">
						<span class="d-block text-center" @click.stop="activeClick(data.item)">{{ data.item.total_active
						}}</span>
					</template>
					<template #cell(completed)="data">
						<span class="d-block text-center" @click.stop="compClick(data.item)">{{ data.item.total_completed
						}}</span>
					</template>
					<template #cell(developing)="data">
						<span class="d-block text-center" @click.stop="deveClick(data.item)">{{ data.item.total_developing
						}}</span>
					</template>
					<template #cell(terminated)="data">
						<span class="d-block text-center" @click.stop="terClick(data.item)">{{ data.item.total_terminated
						}}</span>
					</template>

					<template #empty="scope">
						<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data
						</h4>
					</template>

				</b-table>
			</b-card-code>
		</template>
		<template v-if="permission.study_visit.read">
			<b-card-code title="Study Visits Listing" no-body id="visit-project"
				class="list-project pb-10 card-icon-none black-80-child mb-20 capitalize-head">
				<b-table :sticky-header="true" responsive :items="visit_items" :fields="visit_field"
					class="mb-0 cursor-pointer" @head-clicked="sortClicked_Study" @row-clicked="visit_listing_detail"
					show-empty>
					<template #head(country_name)="data">
						<span class="">{{ data.label }}</span>
						<span
							:class="['cursor-pointer iconsort', { sort: data.field.key == sortStudy.field && sortStudy.type == 'desc' }]"
							style="margin-left: 60px">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
									stroke-linecap="round" stroke-linejoin="round" />
								<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</span>
					</template>
					<template #cell(country_name)="data">
						{{ data.item.country_name }}
					</template>
					<template #cell()="data">
						<span class="text-center d-block">{{ data.value }}</span>
					</template>
					<template #cell(total_complimentary)="data">
						<span class="d-block text-center" @click.stop="study_compClick(data.item)">{{
							data.item.total_complimentary }}</span>
					</template>
					<template #cell(total_paid)="data">
						<span class="d-block text-center" @click.stop="study_painClick(data.item)">{{ data.item.total_paid
						}}</span>
					</template>

					<template #empty="scope">
						<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data
						</h4>
					</template>
				</b-table>
			</b-card-code>
		</template>
	</div>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
	BTable, BBadge, BCardBody,
} from 'bootstrap-vue'
import { codeSticky } from '@/views/table/bs-table/code.js'
import Swiper from "@/views/itees/swiper/Swiper";
import globalAdmin from './model/globalAdmin';
export default {
	mixins: [globalAdmin],
	components: {
		BCardCode,
		BTable,
		BCardBody,
		BBadge,

		Swiper,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: {},
			// My activity projects
			stickyHeader: true,
			fields: [
				{
					key: 'project_name', label: 'Project Name'
				},
				{
					key: 'country_name', label: 'Country'
				}
			],
			items: [],
			// Project listing
			projects_listing_field: [
				{
					key: 'country', label: 'Country'
				},
				{
					key: 'active', label: 'Active'
				},
				{
					key: 'completed', label: 'Completed'
				},
				{
					key: 'developing', label: 'Developing'
				},
				{
					key: 'terminated', label: 'Terminated',
				},
				{
					// key: 'show_details', label: 'show_details',  
				}
			],
			projects_listing_items: [],
			sort: {
				"field": "country",
				"type": "asc"
			},
			sortStudy: {
				"field": "country_name",
				"type": "asc"
			},
			//Study Visit listing
			visit_field: [
				{
					key: 'country_name', label: 'Country'
				},
				{
					key: 'total_complimentary', label: 'Complimentary'
				},
				{
					key: 'total_paid', label: 'Paid'
				},
			],
			visit_items: [],
		}
	},
	created() {
		this.permission.project = this.checkPermission2(this.userData, "Project");
		this.permission.study_visit = this.checkPermission2(this.userData, "Study Visit");
		this.fetchActivities();
		this.fetchProjectListingOverview();
		this.fetchStudyVisitList();
		// console.log(this.permission.project.create, '888888888888888')
	},
	methods: {
		fetchActivities() {
			this.$store
				.dispatch('project/fetchDashboardProjectAcitvity')
				.then(response => {
					this.items = response.data.data
					// console.log('my active project dashboard: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		fetchProjectListingOverview() {
			this.$store
				.dispatch('project/fetchProjectListingOverview', {
					sort: this.sort
				})
				.then(response => {
					this.projects_listing_items = response.data.data
					// console.log('projectslisting: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		fetchStudyVisitList() {
			this.$store
				.dispatch('project/fetchStudyVisitList', {
					sort: this.sortStudy
				})
				.then(response => {
					this.visit_items = response.data.data
					// console.log('sort test: ', this.sortStudy)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		sortClicked(key) {
			if (this.sort.field == key) {
				if (this.sort.type == 'asc') this.sort.type = 'desc'
				else this.sort.type = 'asc'
			}
			else {
				this.sort.field = key
				this.sort.type = 'desc'
			}
			this.fetchProjectListingOverview();
		},
		sortClicked_Study(key) {
			if (this.sortStudy.field == key) {
				if (this.sortStudy.type == 'asc') this.sortStudy.type = 'desc'
				else this.sortStudy.type = 'asc'
			}
			else {
				this.sortStudy.field = key
				this.sortStudy.type = 'desc'
			}
			this.fetchStudyVisitList();
		},
		project_listing_detail(item, index) {
			this.$router.push(`/admin/our-portfolio/project/${item.id}`)
		},
		visit_listing_detail(item) {
			this.$router.push(`/admin/our-portfolio/study-visit/${item.id}`)
		},
		activity_Project(item) {
			this.$router.push(`/admin/our-portfolio/project/${item.country_id}/edit/${item.id}`)
		},
		activeClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/project/${item.id}`, query: { tab: 1 } })
		},
		compClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/project/${item.id}`, query: { tab: 2 } })
		},
		deveClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/project/${item.id}`, query: { tab: 3 } })
		},
		terClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/project/${item.id}`, query: { tab: 4 } })
		},
		// visit study click
		study_painClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/study-visit/${item.id}`, query: { tab: 1 } })
		},
		study_compClick(item) {
			this.$router.push({ path: `/admin/our-portfolio/study-visit/${item.id}`, query: { tab: 2 } })
		},
	}
};
</script>
<style lang="scss">
.card table tr>th,
.card table tr>td {
	padding-left: 21px;
	padding-right: 21px;
}

.card table tr>th {
	font-size: 14px
}

h4 {
	font-size: 16px !important
}
</style>